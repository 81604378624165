// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-service-js": () => import("./../../../src/pages/customer-service.js" /* webpackChunkName: "component---src-pages-customer-service-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-games-js": () => import("./../../../src/pages/resources/games.js" /* webpackChunkName: "component---src-pages-resources-games-js" */),
  "component---src-pages-resources-levels-js": () => import("./../../../src/pages/resources/levels.js" /* webpackChunkName: "component---src-pages-resources-levels-js" */),
  "component---src-pages-resources-pdfs-fun-pages-js": () => import("./../../../src/pages/resources/pdfs/fun-pages.js" /* webpackChunkName: "component---src-pages-resources-pdfs-fun-pages-js" */),
  "component---src-pages-resources-pdfs-getting-to-know-4-group-js": () => import("./../../../src/pages/resources/pdfs/getting-to-know-4-group.js" /* webpackChunkName: "component---src-pages-resources-pdfs-getting-to-know-4-group-js" */),
  "component---src-pages-resources-pdfs-index-js": () => import("./../../../src/pages/resources/pdfs/index.js" /* webpackChunkName: "component---src-pages-resources-pdfs-index-js" */),
  "component---src-pages-resources-pdfs-posters-js": () => import("./../../../src/pages/resources/pdfs/posters.js" /* webpackChunkName: "component---src-pages-resources-pdfs-posters-js" */),
  "component---src-pages-resources-videos-extra-js": () => import("./../../../src/pages/resources/videos/extra.js" /* webpackChunkName: "component---src-pages-resources-videos-extra-js" */),
  "component---src-pages-resources-videos-index-js": () => import("./../../../src/pages/resources/videos/index.js" /* webpackChunkName: "component---src-pages-resources-videos-index-js" */),
  "component---src-pages-resources-videos-intro-js": () => import("./../../../src/pages/resources/videos/intro.js" /* webpackChunkName: "component---src-pages-resources-videos-intro-js" */),
  "component---src-pages-resources-videos-level-1-js": () => import("./../../../src/pages/resources/videos/level1.js" /* webpackChunkName: "component---src-pages-resources-videos-level-1-js" */),
  "component---src-pages-resources-videos-level-2-js": () => import("./../../../src/pages/resources/videos/level2.js" /* webpackChunkName: "component---src-pages-resources-videos-level-2-js" */),
  "component---src-pages-resources-videos-level-5-js": () => import("./../../../src/pages/resources/videos/level5.js" /* webpackChunkName: "component---src-pages-resources-videos-level-5-js" */),
  "component---src-pages-resources-videos-prek-js": () => import("./../../../src/pages/resources/videos/prek.js" /* webpackChunkName: "component---src-pages-resources-videos-prek-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-why-faq-js": () => import("./../../../src/pages/why/faq.js" /* webpackChunkName: "component---src-pages-why-faq-js" */),
  "component---src-pages-why-overview-js": () => import("./../../../src/pages/why/overview.js" /* webpackChunkName: "component---src-pages-why-overview-js" */),
  "component---src-pages-why-testimonials-js": () => import("./../../../src/pages/why/testimonials.js" /* webpackChunkName: "component---src-pages-why-testimonials-js" */),
  "component---src-pages-why-the-science-js": () => import("./../../../src/pages/why/the-science.js" /* webpackChunkName: "component---src-pages-why-the-science-js" */),
  "component---src-templates-level-js": () => import("./../../../src/templates/level.js" /* webpackChunkName: "component---src-templates-level-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

